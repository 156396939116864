import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ESI from 'nodesi';
import {ClientShortHandName, isProduction} from '@snoam/mono-scc';
import {coreHeaderSkin} from "../../utils";
import {IWithSpidContext, withSpidContext} from "../../context/withSpidContext";
import {setCoreHeaderSessionAdapter} from "@snoam/mono-core-header-session-adapter";

if(typeof window !== "undefined") {
    console.log("Setup core header");
    setCoreHeaderSessionAdapter(isProduction(window.location.href), window.location.href);
}

const esi = new ESI({
    // allowedHosts: [coreHeaderUrl],
    // onError: () => '',
});

export interface IHeaderProps extends IWithSpidContext {
    clientName: string;
    clientShortHandName: string;
    hostname: string;
}

export interface IHeaderState {
    header: any;
}

class Header extends React.Component<IHeaderProps, IHeaderState> {

    constructor(props) {
        super(props);

        this.state = {
            header: null,
        }
    }

    makeScript(options: { [k: string]: string | string[] }, body?: string, onload?: () => void) {
        const script = document.createElement("script");
        Object.keys(options).forEach(k => script.setAttribute(k, options[k].toString()));

        if (body) {
            script.innerText = body;
        }
        document.body.appendChild(script);

        if (options.src && onload) {
            // Handle Script loading
            let done = false;

            // Attach handlers for all browsers
            script.onload = (script as any).onreadystatechange = function () {
                if (!done && (!this.readyState ||
                    this.readyState === "loaded" || this.readyState === "complete")) {
                    done = true;
                    onload();

                    // Handle memory leak in IE
                    script.onload = (script as any).onreadystatechange = null;
                    if (document.body && script.parentNode) {
                        document.body.removeChild(script);
                    }
                }
            };
        }
    }

    public componentWillMount() {
        const client = coreHeaderSkin(ClientShortHandName[this.props.clientShortHandName]);
        const {hostname} = this.props;

        esi.process(`
          <esi:include src="https://core-header.schibsted.tech/header?skin=${client}&partial=content" />
          <esi:include src="https://core-header.schibsted.tech/header?skin=${client}&partial=styles" />
        `)
            .then(header => {
                const headerWithCorrectLogoLink = header.replace(/<a class="ch-logo" href="\/">/, `<a class="ch-logo" href="https://www.${hostname}">`);
                this.setState({header: headerWithCorrectLogoLink})
            })
            .catch((e) => console.log(e));

        esi.process(`
          <esi:include src="https://core-header.schibsted.tech/header?skin=${client}&partial=content" />
          <esi:include src="https://core-header.schibsted.tech/header?skin=${client}&partial=scripts" />
        `).then(scriptTags => {
            (scriptTags.match(/<script>([^<]+)/g) || []).forEach(scriptBody => {
                //debug('scriptBody: %s', scriptBody.replace('<script>', ''));
                 this.makeScript({}, scriptBody.replace('<script>', ''));
            });
            let match: any;
            // match attributes of script tags that have a src attribute
            const regex: RegExp = /<script\s+([^>]*src=["'][^>]*)>.*<\/script>/gm;

            while (match = regex.exec(scriptTags)) {
                let attributes = match[1];
                // match key/value pairs in individual groups
                let r = /([\w-]+)=(["'])(.*)\2\s*/gm;
                let options = {};
                let m;
                // construct options object with all the attributes of the script tag
                while (m = r.exec(attributes)) {
                    options[m[1]] = m[3];
                }
                this.makeScript(options, '', this.props.spidContext.actions.checkLoginStatus);
            }
        });

    }

    public render() {
        const {header} = this.state;

        if (header != null) {
            return ReactHtmlParser(header);
        }
        return null;
    }
}

export default withSpidContext(Header);
